<template>
  <div id="all-events-list">
    <v-overlay :value="DeleteLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="addRecordPrompt" persistent max-width="75%">
      <event-plan-create
        :addRecordPrompt="addRecordPrompt"
        @hideDialog="hideAddRecordPrompt"
        :EventId="recordToEdit"
        v-if="addRecordPrompt"
      ></event-plan-create>
    </v-dialog>
    <v-dialog v-model="previewRecordPrompt" persistent max-width="90%">
      <events-preview
        PageTitle="Events Preview"
        :previewRecordPrompt="previewRecordPrompt"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        :EventId="recordToEdit"
        v-if="previewRecordPrompt"
      ></events-preview>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#a4c639"
            >Refresh</v-btn>
          </v-col>
        </v-row>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData1.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.EventStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.EventStatusTxt)"
                    draggable
                    dark
                  >{{ item.EventStatusTxt }}</v-chip>
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.EventPlansCreateFlag == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="addData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-plus-circle</v-icon>
                    </template>
                    <span>Plan Create</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.PreviewFlag == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-search</v-icon>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-document-edit</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import EventPlanCreate from "@/view/pages/erp/events/all-events/event-plans/EventPlanCreate.vue";
import EventsPreview from "@/view/pages/erp/events/all-events/EventsPreview.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    EventPlanCreate,
    EventsPreview
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,
      DeleteLoadingFlag: false,

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      recordToEdit: "",
      addRecordPrompt: false,
      previewRecordPrompt: false,

      selectedData: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.searchForm();
      }
    }
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "events",
        Action: "list"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");

      this.SearchFlag = true;
      this.LoadingFlag = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/all-events/lists";
      var upload = {
        UserInterface: 1,
        Year: this.JciYearCode
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var table = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          console.log({ records });

          if (flag == 1) {
            var options = records.TableOptions;
            console.log({ options });

            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableOptions1 = options;
            thisIns.tableData1 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.SearchFlag = false;
          thisIns.SearchFlag = false;
        });
    },
    addData(tr) {
      console.log("Plan addData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        this.addRecordPrompt = true;
        this.recordToEdit = tr.EventId;
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/events/edit";
        var EventId = tr.EventId;
        console.log({ EventId });
        var params = {
          v1: EventId
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.recordToEdit = tr.EventId;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>