<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
      <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
    </div>
    <v-app-bar color="primary" dense dark elevate-on-scroll scroll-target="#scrolling-techniques-7">
      <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
      <v-card-text>
        <v-container class="py-0">
          <v-row wrap>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Name</h5>
              <p class="preview-content">{{ EventName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Description</h5>
              <p class="preview-content">{{ EventDescription }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Start Date</h5>
              <p class="preview-content">{{ EventStartDate }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event End Date</h5>
              <p class="preview-content">{{ EventEndDate }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Total Limit</h5>
              <p class="preview-content">{{ EventTotalLimit }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Max Limit</h5>
              <p class="preview-content">{{ EventMaxLimit }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3" v-if="AdminFlag">
              <h5 class="blue--text text--lighten">Event Invoice Prefix</h5>
              <p class="preview-content">{{ EventInvoicePrefix }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3" v-if="AdminFlag">
              <h5 class="blue--text text--lighten">Event Registration Link</h5>
              <p class="preview-content">{{ EventRegistrationLink }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Status</h5>
              <p class="preview-content">{{ EventStatusTxt }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Event Payable</h5>
              <p class="preview-content">{{ EventPayable }}</p>
            </v-col>
          </v-row>
          <br />
          <br />
          <v-row wrap>
            <v-col cols="12" md="12">
              <h3 class="red--text text--lighten">Event Plans</h3>
            </v-col>
          </v-row>
          <div class="d-flex justify-content-center mb-3" v-if="TableLoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12" v-if="tableData1.length > 0">
              <h6>{{ tableData1.length }} records found.</h6>
            </v-col>
            <v-col align="center" cols="12" md="12" v-if="tableData1.length <= 0">
              <h6>No records found.</h6>
            </v-col>
            <v-col align="center" cols="12" md="12" v-if="tableData1.length > 0">
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.EventPlansContent="{ item }">
                  <div v-html="item.EventPlansContent"></div>
                </template>
                <template v-slot:item.EventPlansStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.EventPlansStatusTxt)"
                    draggable
                    dark
                  >{{ item.EventPlansStatusTxt }}</v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true
    },
    EventId: {
      required: true
    },
    PageTitle: {
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ProgressFlag: false,
      TableLoadingFlag: false,

      editRecordPrompt: false,

      RoleId: "",
      AdminFlag: false,

      menu1: false,
      menu2: false,

      row: {},

      EventName: "",
      EventDescription: "",
      EventRegistrationLink: "",
      EventTotalLimit: 0,
      EventMaxLimit: 0,
      EventPayable: 0,
      EventStatus: 0,
      EventStatusTxt: "",
      EventStartDate: new Date().toISOString().slice(0, 10),
      EventEndDate: new Date().toISOString().slice(0, 10),
      EventInvoicePrefix: "",
      EventPlans: [],

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  mounted() {},
  watch: {
    row: function() {
      console.log("watch row");

      this.EventName = this.row.EventsName;
      this.EventDescription = this.row.Description;
      this.EventTotalLimit = this.row.TotalLimit;
      this.EventMaxLimit = this.row.MaxLimit;
      this.EventPayable = this.row.EventPayableTxt;
      this.EventStatus = this.row.ActiveStatus;
      this.EventStatusTxt = this.row.ActiveStatusTxt;
      this.EventStartDate = this.row.StartDateTxt;
      this.EventEndDate = this.row.EndDateTxt;
      this.EventInvoicePrefix = this.row.InvoicePrefix;
      this.EventRegistrationLink = this.row.RegistrationLink;
    }
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getEventPlanDetails();
      this.getEventDetails();
    },
    getEventPlanDetails() {
      console.log("getEventPlanDetails is called");

      var EventId = this.EventId;
      console.log("EventId=" + EventId);

      if (EventId != "") {
        this.TableLoadingFlag = true;
        this.tableData1 = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/event-plans/lists";
        var upload = {
          UserInterface: 1,
          Event: EventId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.TableLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            console.log({ records });
            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;

            thisIns.tableColumns1 = records.TableHeader;

            if (flag == 1) {
              thisIns.tableData1 = records.TableData;
              // thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.TableLoadingFlag = false;
          });
      } else {
        var message = "";
        if (EventId == "") {
          message += "Event Details Not found.";
        }
        this.sweetAlert("error", message, false);
      }
    },
    getEventDetails() {
      console.log("getEventDetails is called");

      var EventId = this.EventId;
      console.log("EventId=" + EventId);

      if (EventId != "") {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/events/show";
        var upload = {
          UserInterface: 1,
          EventCode: EventId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            console.log({ records });

            if (flag == 1) {
              thisIns.row = records;
              // thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        if (EventId == "") {
          message += "Event Details Not found.";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    this.AdminFlag = RoleId == 2 ? true : false;

    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>