<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <h6>
                    <span class="text-danger">*</span> indicates required
                    field
                  </h6>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Plan Name
                    </h6>
                  </label>
                  <v-text-field v-model="PlanName" :rules="PlanNameRules" outlined dense required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Plan Description
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="PlanDescription"
                    :rules="PlanDescriptionRules"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Plan Amount
                    </h6>
                  </label>
                  <v-text-field
                    v-model="PlanAmount"
                    :rules="PlanAmountRules"
                    required
                    outlined
                    dense
                    min="0"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Plan Tax
                    </h6>
                  </label>
                  <v-text-field
                    v-model="PlanTax"
                    :rules="PlanTaxRules"
                    required
                    outlined
                    dense
                    min="0"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Plan Max Limit
                    </h6>
                  </label>
                  <v-text-field
                    v-model="PlanMaxLimit"
                    :rules="PlanMaxLimitRules"
                    required
                    outlined
                    dense
                    min="0"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Plan Recommended
                    </h6>
                  </label>
                  <v-switch
                    inset
                    v-model="PlanRecommended"
                    :label="`${
                            PlanRecommended
                              ? 'Yes'
                              : 'No'
                          }`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Plan Status
                    </h6>
                  </label>
                  <v-switch
                    inset
                    v-model="PlanStatus"
                    :label="`${
                            PlanStatus
                              ? 'Active'
                              : 'Inactive'
                          }`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="12">
                  <label>
                    <h6>
                      Plan Content
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <tiptap-vuetify v-model="PlanContent" :extensions="extensions" />
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >save</v-btn>
                  <v-btn
                    @click.prevent="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="red"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Close</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  mixins: [common],
  components: { TiptapVuetify },
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true
    },
    EventId: {
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,

      extensions: [
        History,
        Blockquote,
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        HorizontalRule,
        Paragraph,
        HardBreak
      ],

      PlanName: "",
      PlanDescription: "",
      PlanContent: "",
      PlanAmount: 0,
      PlanTax: 0,
      PlanMaxLimit: 0,
      PlanRecommended: false,
      PlanStatus: false,

      PlanNameRules: [v => !!v || "Plan Name is required"],
      PlanDescriptionRules: [],
      PlanContentRules: [],
      PlanAmountRules: [v => !!v || "Plan Amount is required"],
      PlanTaxRules: [],
      PlanMaxLimitRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;
      }
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "events",
        Action: "create_1"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var EventId = this.EventId;
      var PlanTax = this.PlanTax == "" ? 0 : this.PlanTax;
      var PlanStatus = this.PlanStatus ? 1 : 0;
      var PlanRecommended = this.PlanRecommended ? 1 : 0;

      console.log("EventId=" + EventId);

      if (validate1 && EventId != "") {
        var upload = {
          UserInterface: 1,
          Event: this.EventId,
          PlanName: this.PlanName,
          PlanDescription: this.PlanDescription,
          PlanContent: this.PlanContent,
          PlanAmount: this.PlanAmount,
          PlanTax: PlanTax,
          PlanMaxLimit: this.PlanMaxLimit,
          PlanRecommended: PlanRecommended,
          PlanStatus: PlanStatus
        };
        console.log({ upload });

        this.progessStart = 1;
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/event-plans/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (!EventId) {
          message += "Your session is expired. Kindly try again. ";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>